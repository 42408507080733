import domtoimage from 'dom-to-image';
import JsBarcode from 'jsbarcode';

const body = document.body;

function renderItem(skuInput, upcInput) {
  const markup = `
    <div class="bcContainer" id="bcContainer-${ upcInput }">
        <p id="sku">Sku: ${ skuInput }</p>
        <svg id="barcode-${ upcInput }"></svg>
    </div>
    `;

  body.insertAdjacentHTML('afterbegin', markup);

  JsBarcode(`#barcode-${ upcInput }`, `${ upcInput }`, {
    format: 'UPC',
    height: 60,
    width: 1.6
  })

}

function parseArray(arr) {
  arr.forEach(el => {
    renderItem(el[0], el[1]);
  })
}

function saveImage(node, arrEl) {
  domtoimage.toJpeg(document.getElementById(`bcContainer-${ arrEl[1] }`), { quality: 0.95 })
    .then(function (dataUrl) {
      var link = document.createElement('a');
      link.download = `${ arrEl[0] }-${ arrEl[1] }.jpeg`;
      link.href = dataUrl;
      link.click();
    });
}

function task() {
  setTimeout(saveImage,);
}

function saveImageArr(arr) {
  arr.forEach(el => {
    let node = document.getElementById(`bcContainer-${ el[1] }`);
    let i = arr.indexOf(el);
    setTimeout(function () {
      saveImage(node, el);
      console.log(i + 1);
    }, 600 * i);

  });
}

function mainFunc(arr) {
  console.log(arr);
  parseArray(arr);
  saveImageArr(arr);
}

let skuField = document.getElementById('sku-field').value;
let upcField = document.getElementById('upc-field').value;
const upcError = document.querySelector('.error--UPC');
const generateBtn = document.getElementById('generate');

generateBtn.addEventListener('click', () => {
  console.log('clicked');
  skuField = document.getElementById('sku-field').value;
  upcField = document.getElementById('upc-field').value;

  //check upc
  if (upcField.length !== 12) {
    upcError.classList.add('show');
    return;
  }

  const codeArr = [[skuField, Number(upcField)]];
  console.log(codeArr);

  mainFunc(codeArr);
  setTimeout(() => {
    location.reload();
  }, 1000);
})
